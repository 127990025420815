<template>
  <ais-instant-search :middlewares="middlewares" :routing="routing" :search-client="searchClient"
                      index-name="dev_extranet_date_created_desc"
                      placeholder="Recherche">
    <ais-configure
        :disjunctive-facets-refinements.camel="{big_category : ['Aall','Creglement']}"
    />
    <div class="company-title-lip">
      <div class="company-name-lip">QBE</div>
      <div class="company-folders-lip">Sinistres</div>
    </div>
    <!-- Big category -->
    <div class="list-filter-lip">
      <div class="list-filter-left-lip">
        <ul>
          <ais-menu :sort-by='["name:asc"]' attribute="big_category">
            <template v-slot="{ items, canRefine, refine }">
              <li
                  v-for="item in items"
                  :key="item.value"
                  :disabled="!canRefine"
                  :selected="item.isRefined"
                  :value="item.value"
                  @click="
										(e) => {
											getActive(e.target);
											refine(item.value)
										}
									"
              >
                <a :class="[item.label === filterDashboard ? 'active link' : 'link' , !filterDashboard && item.label === 'Aall' ? 'active' : '']">
                  {{ translator('cat_' + item.label) }} ({{ item.count }})
                </a>
              </li>
            </template>
          </ais-menu>
        </ul>
      </div>
      <div class="list-filter-right-lip">
        <div class="filter-btn-lip"><a id="openFilter" href="javascript:void(0)" @click="openFilters"
                                       v-on:click="openFilter">Filtrer</a></div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!-- FILTRES -->
    <div class="filter-dropdown-main-lip closed">
      <div class="filter-dropdown-lip">
        <div class="mdirect-filter-menu1-omp">
          <div class="contentSelected">
            <a id="filter_menu1_omp" class="filter-dropdown-title-lip open_dektop_filter" href="javascript:void(0)"
               v-on:click="openDetailsFilter($event.target)">DATE DE CRÉATION</a>
            <ais-refinement-list :sort-by='["name:asc"]' attribute="date_created">
              <template v-slot="{items, refine}">
                <div class="dropdown-menu md-filter-dropmenu1-omp open_filter_menu1_omp">
                  <div>
                    <div v-for="(item,key) in items" :key="key" class="md-filter-info-omp" @click="
															(e) => {
																refine(item.value);
																addBackgroundFilter(e.target);
															}"
                    >
                      <p id="valueSelect" @click.prevent="">{{ item.value }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </ais-refinement-list>
          </div>
          <div class="contentSelected">
            <a id="filter_menu1_omp" class="filter-dropdown-title-lip open_dektop_filter" href="javascript:void(0)"
               v-on:click="openDetailsFilter($event.target)">CODE</a>
            <ais-refinement-list :sort-by='["name:asc"]' attribute="garantees">
              <template v-slot="{items, refine}">
                <div class="dropdown-menu md-filter-dropmenu1-omp open_filter_menu1_omp">
                  <div>
                    <div v-for="(item,key) in items" :key="key" class="md-filter-info-omp" @click="
															(e) => {
																refine(item.value);
																addBackgroundFilter(e.target);
															}"
                    >
                      <p id="valueSelect" @click.prevent="">{{ item.value }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </ais-refinement-list>
          </div>
          <div class="contentSelected">
            <a id="filter_menu1_omp" class="filter-dropdown-title-lip open_dektop_filter" href="javascript:void(0)"
               v-on:click="openDetailsFilter($event.target)">catégorisation</a>
            <ais-refinement-list :sort-by='["name:asc"]' attribute="category">
              <template v-slot="{items, refine}">
                <div class="dropdown-menu md-filter-dropmenu1-omp open_filter_menu1_omp">
                  <div>
                    <div v-for="(item,key) in items" :key="key" class="md-filter-info-omp" @click="
															(e) => {
																refine(item.value);
																addBackgroundFilter(e.target);
															}"
                    >
                      <p id="valueSelect" @click.prevent="">{{ translator(item.value) }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </ais-refinement-list>
          </div>
          <div class="contentSelected">
            <a id="filter_menu1_omp" class="filter-dropdown-title-lip open_dektop_filter" href="javascript:void(0)"
               v-on:click="openDetailsFilter($event.target)">Gestionnaire</a>
            <ais-refinement-list :sort-by='["name:asc"]' attribute="gestionnaire">
              <template v-slot="{items, refine}">
                <div class="dropdown-menu md-filter-dropmenu1-omp open_filter_menu1_omp">
                  <div>
                    <div v-for="(item,key) in items" :key="key" class="md-filter-info-omp" @click.prevent="
															(e) => {
																refine(item.value);
																addBackgroundFilter(e.target);
															}"
                    >
                      <p id="valueSelect">{{ item.value }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </ais-refinement-list>
          </div>
          <div class="contentSelected">
            <a id="filter_menu1_omp" class="filter-dropdown-title-lip open_dektop_filter" href="javascript:void(0)"
               v-on:click="openDetailsFilter($event.target)">Date mise à jour</a>
            <ais-refinement-list :sort-by='["name:asc"]' attribute="updatedAt">
              <template v-slot="{items, refine}">
                <div class="dropdown-menu md-filter-dropmenu1-omp open_filter_menu1_omp">
                  <div>
                    <div v-for="(item,key) in items" :key="key" class="md-filter-info-omp" @click.prevent="
															(e) => {
																refine(item.value);
																addBackgroundFilter(e.target);
															}"
                    >
                      <p id="valueSelect" @click.prevent="">{{ item.value }}</p>
                    </div>
                  </div>
                </div>
              </template>
            </ais-refinement-list>
          </div>
        </div>
      </div>
    </div>
    <!-- AFFICHAGE DES FILTRES ACTIFS-->
    <div class="filter-selected-tags-lip">
      <div class="filter-tags-lip">
        <ais-current-refinements>
          <template v-slot="{ items, createURL }">
            <div v-for="item in items" :key="item.attribute" class="filter-tags-in-lip">
              <a v-if="item.label !== 'big_category'" href="#">{{ translator(item.label) }} :
                <span
                    v-for="refinement in item.refinements"
                    :key="[
											refinement.attribute,
											refinement.type,
											refinement.value,
											refinement.operator
											].join(':')"
                >
											<div
                          :href="createURL(refinement)"
                          @click.prevent="item.refine(refinement)"
                      >
											{{ refinement.label }} <i class="far fa-times-circle"></i>
											</div>
										</span>
              </a>
            </div>
          </template>
        </ais-current-refinements>
      </div>
    </div>
    <!-- Content Tab -->
    <div class="contentForScroll">
      <table id="list_table" class="list-table-lip">
        <!-- Header Tab -->
        <thead>
        <tr>
          <ais-sort-by
              :items="[
							{ value: 'dev_extranet_date_created_asc', label: 'created asc' },
							{ value: 'dev_extranet_date_created_desc', label: 'created desc' },
							{ value: 'dev_extranet_ref_desc', label: 'ref desc.' },
							{ value: 'dev_extranet_ref_asc', label: 'ref asc.' },
						]"
          >
            <template v-slot="{ refine }">
              <th id="dev_extranet_date_created_" class="sorting_desc" emplacement="head" style="cursor: pointer"
                  typeData="creation"
                  @click="(e) => {
													sort(e.target).then((res) => {
														refine(res);
													})
												}">
                Création
              </th>
              <th id="dev_extranet_ref_" class="sorting_desc" emplacement="head" style="cursor: pointer"
                  typeData="reference"
                  @click="(e) => {
													sort(e.target).then((res) => {
														refine(res);
													})
											}">
                Réf. sin.
              </th>
              <th id="dev_extranet_type_" class="sorting_desc" emplacement="head" style="cursor: pointer"
                  typeData="type"
                  @click="(e) => {
													sort(e.target).then((res) => {
														refine(res);
													})
												}">
                TYPE
              </th>
              <th id="dev_extranet_code_" class="sorting_desc" emplacement="head" style="cursor: pointer"
                  typeData="code"
                  @click="(e) => {
													sort(e.target).then((res) => {
														refine(res);
													})
												}">
                Code
              </th>
              <th id="dev_extranet_declarant_" class="sorting_desc" emplacement="head" style="cursor: pointer"
                  typeData="name"
                  @click="(e) => {
													sort(e.target).then((res) => {
														refine(res);
													})
												}">
                Déclarant
              </th>
              <th id="dev_extranet_cat_" class="sorting_desc" emplacement="head" style="cursor: pointer" typeData="cat"
                  @click="(e) => {
													sort(e.target).then((res) => {
														refine(res);
													})
												}">
                Catégorisation
              </th>
              <th id="dev_extranet_status_" class="sorting_desc" emplacement="head" style="cursor: pointer"
                  typeData="state"
                  @click="(e) => {
													sort(e.target).then((res) => {
														refine(res);
													})
												}">
                Statut
              </th>
              <th id="dev_extranet_gest_" class="sorting_desc" emplacement="head" style="cursor: pointer"
                  typeData="gest"
                  @click="(e) => {
													sort(e.target).then((res) => {
														refine(res);
													})
												}">
                Gest.
              </th>
              <th id="dev_extranet_update_" class="sorting_desc" emplacement="head" style="cursor: pointer"
                  typeData="update"
                  @click="(e) => {
													sort(e.target).then((res) => {
														refine(res);
													})
												}">
                Der. act.
              </th>
            </template>
          </ais-sort-by>
        </tr>
        </thead>
        <tbody>
        <ais-hits>
          <template v-slot="{ items }">
            <tr v-for="sinister in items" :id="'elementforSearch' + sinister.objectID" :key="sinister.objectID"
                :style="'cursor:pointer;'+ getImportance(sinister.importance)"
                class="itemSearch"
                @click="this.$router.push(`/gestionnaire/sinistre/details/${sinister.objectID}`)">
              <td type="body" typeData="creation">{{ sinister.date_created }}</td>
              <td class="ref-sin-cip" type="body" typeData="reference">{{ sinister.sinister_reference }}</td>
              <td class="rcd-lip" type="body" typeData="type">
                {{ sinister.garantees ? sinister.garantees.toString() : '' }}
              </td>
              <td class="crac-lip">
                <div class="code-btn-cip">{{ sinister.code_gestion }}</div>
              </td>
              <td class="sinistre-lip " type="body" typeData="name">{{ sinister.name }}</td>
              <td class="dernier-lip" type="body" typeData="cat">
                <div
                    :style="getBackground(sinister.category_id)"
                    class="categ-btn-cip">
                  {{ translator(sinister.category) }}
                </div>
              </td>

              <td class="status-lip" type="body" typeData="state">
                <div
                    :style="!sinister.status ? 'color : #144BB8 ; border : #144BB8 solid 1px' : 'color : #000000 ; border : #000000 solid 1px'"
                    class="unread-lip">
                  {{ !sinister.status ? 'A traiter' : 'En cours' }}
                </div>
              </td>

              <td class="gestionnaire-lip" type="body" typeData="gest">
                <div v-if="sinister.initial_gestionnaire" class="gest-name-cip">{{ sinister.initial_gestionnaire }}
                </div>
              </td>
              <td class="traiter-lip" type="body" typeData="update">{{ sinister.updatedAt }}</td>
            </tr>
          </template>
        </ais-hits>
        </tbody>
      </table>
    </div>
    <ais-pagination
        :class-names="{
					'ais-Pagination': 'MyCustomPagination',
					'ais-Pagination-list': 'MyCustomPaginationList',
					'ais-Pagination-item' : 'itemPage',
					'ais-Pagination-item--selected' : 'selectedItem',
					'ais-Pagination-item--nextPage' : 'itemArrow',
					'ais-Pagination-item--previousPage' : 'itemArrow',
					'ais-Pagination-link' : 'linkArrow'
				}"
        :show-last="true"
        @click="scrollTop()"
    />
  </ais-instant-search>
</template>
<script>
import algoliasearch from 'algoliasearch/lite';
import $ from 'jquery';
import {history as historyRouter} from 'instantsearch.js/es/lib/routers';
import {singleIndex as singleIndexMapping} from 'instantsearch.js/es/lib/stateMappings';

// eslint-disable-next-line no-unused-vars
function middleware({instantSearchInstance}) {
  return {
    subscribe() {
      const addDetail = setInterval(() => {
        if ($("tr[id^='elementforSearch']").length >= 1) {
          $("tr[id^='elementforSearch']").unwrap();
          $("th[id^='dev_extranet']").unwrap();
          $('table').fadeIn();
          clearInterval(addDetail)
        }
      }, 1000);

    },
  }
}

export default {
  name: 'Listing V2',
  props: ['filterDashboard'],
  data() {
    return {
      searchClient: algoliasearch(
          'UNW6ZYQKW6',
          '44a464f2c86ebaf5ce203b99f74b927b'
      ),
      routing: {
        router: historyRouter(),
        stateMapping: singleIndexMapping('dev_extranet_date_created_desc'),
      },
      middlewares: [middleware],
      catListing: null,
    };
  },
  methods: {
    scrollTop() {
      $('.contentForScroll').animate({scrollTop: 0}, "slow");
    },
    getImportance(importance) {
      let background;
      switch (importance) {
        case(0):
          background = "none";
          break;
        case(undefined):
          background = "none";
          break;
        case(1):
          background = "#FFFCF5";
          break;
        case(2):
          background = "#FFF5F5";
          break;
      }
      return 'background : ' + background;
    },
    getBackground(id) {
      let background;
      let fontColor;
      console.log(id);
      switch (id) {
        case(1) :
          background = '#BFF3D4';
          break;
        case(2) :
          background = '#FAFAFA';
          break;
        case(3) :
          background = '#FAFAFA';
          break;
        case(4) :
          background = '#A5358C';
          fontColor = 'white';
          break;
        case(5) :
          background = '#EAEAEA';
          fontColor = 'black';
          break;
        case(14) :
          background = '#EAEAEA';
          fontColor = 'black';
          break;
        case(13) :
          background = '#EAEAEA';
          fontColor = 'black';
          break;
        case(99) :
          background = '#2B7FFF';
          fontColor = 'white';
          break;
      }
      return fontColor ? 'background : ' + background + ';color : ' + fontColor : 'background : ' + background;
    },
    openFilters() {
      $('.filter-dropdown-main-lip').slideToggle();
      console.log($('#element_131'));
      $('#element_131').unwrap();
    },
    getNameCategory(id) {
      let name;
      switch (id) {
        case(1) :
          name = 'Orientation à définir';
          break;
        case(2) :
          name = 'Instructions en cours';
          break;
        case(3) :
          name = 'Instructions en cours';
          break;
        case(4) :
          name = 'Réglement en cours';
          break;
        case(5) :
          name = 'Dossier clos';
          break;
        case(14) :
          name = 'Refus Direct';
          break;
        case(13) :
          name = 'Proposition Direct';
          break;
        case(99) :
          name = 'Message à traiter';
          break;
      }
      return name;
    },
    sort(e) {
      return new Promise((resolve) => {
        const className = $(e).attr('class');
        const id = $(e).attr('id');
        const sort = className.split('_')[1];
        console.log(className, id, sort)

        // GO TO ASC
        if (sort === 'desc') {
          $('#' + id).removeClass('sorting_desc');
          $('#' + id).addClass('sorting_asc');
        } else {
          $('#' + id).removeClass('sorting_asc');
          $('#' + id).addClass('sorting_desc');
        }
        resolve(id + sort);
      });
    },
    openDetailsFilter(event) {
      $('.open_filter_menu1_omp').stop().slideUp();
      $(event).next().children('.md-filter-dropmenu1-omp').stop().slideToggle();
      $(event).toggleClass('boldFont')
    },
    addBackgroundFilter(e) {
      const id = $(e).attr('id');
      if (id === 'valueSelect') {
        $(e).parent().toggleClass('selectedFilters');
      } else {
        $(e).toggleClass('selectedFilters');
      }
    },
    translator(data) {
      return this.$store.getters['translator/translate'](data)
    },
    getActive(e) {
      $('.link').removeClass('active');
      $(e).addClass('active');
      $("th[emplacement='head']").each(function () {
        const value = $(this).attr('typeData');

        const getElement = $('td[typeData=' + value + ']').outerWidth();

        $(this).css("width", getElement);
      })
    }
  }
}
</script>
<style>
#list_table {
  width: 100%;
}

.contentForScroll {
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  position: relative;
}

.list-right-lip {
  overflow: scroll;
}

th, td {
  text-align: center;
}

.MyCustomPaginationList {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  padding-right: 15px;
}

.itemList {
  width: auto;
}

.selectedItem {
  color: #fff;
  background-color: #0096db;
  border-color: #0096db;
  text-decoration: none;
}

.itemPage {
  padding: .1rem .4rem;
  display: flex;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
}

.selectedItem .linkArrow {
  text-decoration: none;
  color: #fff;
}

.selectedFilters {
  color: #00B8E4;
  background: #F0FCFF
}
</style>

<style scoped>
table {
  display: none;
}

.filter-tags-in-lip a {
  display: flex;
}

.filter-tags-in-lip a span {
  margin-left: 15px;
}

.link {
  cursor: pointer;
}

.boldFont {
  color: #000;
}

.tableFixed {
  position: sticky;
  top: 0;
  max-width: 100%;
}

.md-filter-info-omp {
  display: block;
  padding: 10px 36px 10px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000;
  cursor: pointer;
  position: relative
}

.md-filter-info-omp::after {
  position: absolute;
  content: '';
  right: 16px;
  left: auto;
  top: 11px;
  width: 18px;
  height: 18px;
  border: 2px solid #C4C8D4;
  border-radius: 3px;
}

.selectedFilters::after {
  background: #00B8E4;
}

.mdirect-filter-menu1-omp {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.contentSelected {
  position: relative;
  margin-right: 15px
}

thead .sorting_asc:after {
  content: '';
  background: url(~@/assets/images/sort_asc.svg) no-repeat center center;
  width: 9px;
  height: 14px;
  background-size: 9px;
  position: absolute;
  display: inline-block;
  margin: -2px 0 0 6px;
  vertical-align: middle;
  transition: all 0s linear;
}

thead .sorting_desc:after {
  content: '';
  background: url(~@/assets/images/sort_desc.svg) no-repeat center center;
  width: 9px;
  height: 14px;
  background-size: 9px;
  position: absolute;
  display: inline-block;
  margin: -2px 0 0 6px;
  vertical-align: middle;
  transition: all 0s linear;
}
</style>
