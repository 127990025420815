<template>
	<div class="declaring-block-main-aedecp optiscroll stepThree">
		<div class="declaring-block-in-aedecp">
			<div class="common-title-main-aehp common-title-left-aehp">
				<h2>Renseignements relatifs à la construction</h2>
			</div>
			<div class="declaring-form-main-aedecp">
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">Nature de l’opération</div>
							<div class="defa-option-list-aedecp">
								<div class="defa-option1-aedecp">
									<div class="custom-control custom-radio">
										<input v-model="construction_information.nature_operation" checked="true" type="radio" id="default_option1" value="apartment" name="default-option1" class="custom-control-input">
										<label class="custom-control-label" for="default_option1">Immeuble</label>
									</div>
								</div>
								<div class="defa-option1-aedecp">
									<div class="custom-control custom-radio">
										<input  v-model="construction_information.nature_operation" type="radio" id="default_option2" value="house" name="default-option1" class="custom-control-input">
										<label class="custom-control-label" for="default_option2">Maison individuelle</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">Adresse de la construction sinistrée</div>
							<div class="default-sub-form-row1-aedecp">
								<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp add-field1-aedecp">
									<div class="default-form-field1-Stit-aedecp">Rue</div>
									<div class="defa-form-field-Tbox-aedecp">
										<input v-model="construction_information.street" @change="(e) => remove(e.target)" type="text" placeholder="" >
									</div>
								</div>
							</div>
							<div class="default-sub-form-row1-aedecp">
								<div class="col-lg-7 col-md-7 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W65-aedecp">
									<div class="default-form-field1-Stit-aedecp">Ville</div>
									<div class="defa-form-field-Tbox-aedecp">
										<input v-model="construction_information.city" @change="(e) => remove(e.target)" type="text" placeholder="" >
									</div>
								</div>
								<div class="col-lg-5 col-md-5 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W35-aedecp">
									<div class="default-form-field1-Stit-aedecp">Code postal</div>
									<div class="defa-form-field-Tbox-aedecp">
										<input v-model="construction_information.zipcode" @change="(e) => remove(e.target)" type="text" placeholder="" >
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">Nom du constructeur</div>
							<div class="defa-form-field-Tbox-aedecp">
								<input v-model="construction_information.manufacturer_name" @change="(e) => remove(e.target)" type="text" placeholder="" >
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">Nom du premier propriétaire</div>
							<div class="defa-form-field-Tbox-aedecp">
								<input v-model="construction_information.first_owner_name" @change="(e) => remove(e.target)"  type="text" placeholder="" >
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">Date règlementaire d’ouverture du chantier (DROC)</div>
							<div class="defa-form-field-Tbox-aedecp defa-Ffield-W50-aedecp input-group defa-form-Dtpicker-aedecp date form_date">
								<input v-model="construction_information.settlement_date_opening_site" @change="(e) => remove(e.target)" type="date" class="" placeholder="-">
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">Date de réception des travaux</div>
							<div class="defa-form-field-Tbox-aedecp defa-Ffield-W50-aedecp input-group defa-form-Dtpicker-aedecp date form_date">
								<input v-model="construction_information.receipt_work_date" type="date" @change="(e) => remove(e.target)" class="" placeholder="-">
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">La réception a-t-elle fait l’objet de réserves ?</div>
							<div class="defa-option-list-aedecp">
								<div class="defa-option1-aedecp">
									<div class="custom-control custom-radio">
										<input v-model="construction_information.reservations" type="radio" checked="true" :value="true" id="default_option3" name="reservations-option1" class="custom-control-input">
										<label class="custom-control-label" for="default_option3">Oui</label>
									</div>
								</div>
								<div class="defa-option1-aedecp">
									<div class="custom-control custom-radio">
										<input v-model="construction_information.reservations" :value="false" type="radio" id="default_option4" name="reservations-option1" class="custom-control-input">
										<label class="custom-control-label" for="default_option4">Non</label>
									</div>
								</div>
							</div>
							<div class="default-note-text-aedecp">
								<p>(Attention : à ne pas confondre avec la déclaration d’achèvement des travaux ou  la date de livraison de l’ouvrage – La réception des travaux est actée par Procès-verbal de réception signé au contradictoire du constructeur)</p>
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">Nom de l’occupant</div>
							<div class="defa-form-field-Tbox-aedecp">
								<input v-model="construction_information.occupant_name" type="text" @change="(e) => remove(e.target)" placeholder="" >
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-12 col-md-12 col-sm-12 col-12 defa-Ffield1-col1-aedecp">
							<div class="default-form-field1-tit-aedecp">L’occupant est</div>
							<div class="defa-option-list-aedecp">
								<div class="defa-option1-aedecp">
									<div class="custom-control custom-radio">
										<input v-model="construction_information.occupant_state" checked="true" value="owner" type="radio" id="default_option5" name="occupant-option1" class="custom-control-input">
										<label class="custom-control-label" for="default_option5">Propriétaire</label>
									</div>
								</div>
								<div class="defa-option1-aedecp">
									<div class="custom-control custom-radio">
										<input v-model="construction_information.occupant_state" value="tenant" type="radio" id="default_option6" name="occupant-option1" class="custom-control-input">
										<label class="custom-control-label" for="default_option6">Locataire</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="default-form-field1-aedecp">
					<div class="default-form-field1-in-aedecp">
						<div class="col-lg-5 col-md-5 col-sm-6 col-12 defa-Ffield1-col1-aedecp defa-Ffield-W35-aedecp">
							<div class="default-form-field1-tit-aedecp">N° de téléphone de l’occupant</div>
							<div class="defa-form-field-Tbox-aedecp">
								<input v-model="construction_information.occupant_tel" type="text" @change="(e) => remove(e.target)" placeholder="" >
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="default-form-btn-bar-aedecp">
				<div class="default-form-btn-aedecp">
					<a href="#" v-on:click="nextStep" class="btn default-btn-aehp">Continuer</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapActions} from 'vuex';
import $ from 'jquery';
export default{
	name: 'StepThree',
	props : ['type'],
    components: {
    },
    data(){
        return{
            // construction_information : {
            //     nature_operation : 'apartment',
            //     street : null,
            //     city : null,
            //     zipcode : null,
            //     manufacturer_name : null,
            //     first_owner_name : null,
            //     settlement_date_opening_site : null,
            //     receipt_work_date : null,
            //     reservations : true,
            //     occupant_name : null,
            //     occupant_state : 'owner',
            //     occupant_tel : null
            // }
            construction_information : {
                nature_operation : 'house',
				street : '8 place Msgr Rumeaux',
				city : 'Angers',
				zipcode : '49100',
                manufacturer_name : 'Toto',
                first_owner_name : 'Gentilhomme',
                settlement_date_opening_site : '2020-01-11',
                receipt_work_date : '2020-01-11',
                reservations : true,
                occupant_name : 'Gentilhomme',
                occupant_state : 'owner',
                occupant_tel : '6049089812'
            }
        }
    },
    methods:{
		...mapActions('createSinistre', ['addConstructionInformation']),
		nextStep(){
			if(this.type === 'assured'){
				let numberInputThree = 0;
				let numberGoodInput = 0;
				$( ".stepThree input:text, .stepThree input[type='date']" ).each(function() {
					numberInputThree++;
					if(!$(this).val()){
						$(this).addClass('errorInput');
					}
					else{
						numberGoodInput++
						$(this).removeClass('errorInput');
					}
				});
				if(numberInputThree === numberGoodInput){
					this.addConstructionInformation(this.construction_information);
					this.$emit('click', {action : 'StepFour'});
				}
				console.log('numberInputThree', numberInputThree);
				console.log('numberGoodInput', numberGoodInput);
			}
			else{
				this.addConstructionInformation(this.construction_information);
				this.$emit('click', {action : 'StepFour'});
			}
		},
		remove(e){
			$(e).removeClass('errorInput');
		}
    }
}
</script>
<style scoped>
.errorInput{
	border: solid 2px #f03737;
}
</style>
